import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock/';
import IfStore from 'Component/IfStore';
import ProductSlider from 'Component/ProductSlider';
import { SliderProductsType } from 'Type/SliderProductsType';

import './HomepageSlider.style';

/** @namespace Pwa/Component/HomepageSlider/Component */
export class HomepageSliderComponent extends PureComponent {
    static propTypes = {
        sliderProducts: SliderProductsType.isRequired,
        blackFridayCounterCarouselProducts: SliderProductsType.isRequired,
    };

    renderTitle() {
        return (
        <div className="row">
            <div className="col-12 title">
                <IfStore storeCode="default">
                    <CmsBlock identifier="title-home-slider" />
                </IfStore>
                <IfStore storeCode="spain">
                    <CmsBlock identifier="title-home-slider-es" />
                </IfStore>
            </div>
        </div>
        );
    }

    render() {
        const { sliderProducts } = this.props;
        const { blackFridayCounterCarouselProducts } = this.props;
        const config = {
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 4,
                },
                1920: {
                    slidesPerView: 4,
                },
            },
            theme: 'FlashSales',
            stickers: 'flash_sales_slider',
        };

        return sliderProducts.length > 0 && (
            <div block="HomepageSlider" mods={ { blackFridayCounterCarouselProducts } }>
                { this.renderTitle() }
                <ProductSlider
                  items={ sliderProducts }
                  config={ config }
                  list="Home page products slider"
                  isBlackFriday="false"
                />
            </div>
        );
    }
}

export default HomepageSliderComponent;
