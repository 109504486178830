/* eslint-disable no-magic-numbers */

export const RELOAD_EVENT_NAME = 'mc-reload-products';

/** @namespace Pwa/Util/ReloadProductsEvent/reloadProducts */
export const reloadProducts = () => {
    const event = new Event(RELOAD_EVENT_NAME);
    window.dispatchEvent(event);
};

/** @namespace Pwa/Util/ReloadProductsEvent/bindReloadProducts */
export const bindReloadProducts = (callback) => {
    window.addEventListener(RELOAD_EVENT_NAME, callback);
};

/** @namespace Pwa/Util/ReloadProductsEvent/unbindReloadProducts */
export const unbindReloadProducts = (callback) => {
    window.removeEventListener(RELOAD_EVENT_NAME, callback);
};

/** @namespace Pwa/Util/ReloadProductsEvent/autoReloadProducts */
export const autoReloadProducts = () => {
    const initialTime = new Date();
    const timeout = ((60 - initialTime.getMinutes()) * 60 + 15) * 1000;

    return setTimeout(reloadProducts, timeout);
};
