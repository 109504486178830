import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsBlock from 'Component/CmsBlock';
import HomepageSlider from 'Component/HomepageSlider';
import IfStore from 'Component/IfStore';
import ResetScroll from 'Component/ResetScroll';
import Swogo from 'Component/Swogo/Swogo.container';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { autoReloadProducts } from 'Util/reloadProductsEvent';

import './HomePage.override.style.scss';

export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */ 'Store/Meta/Meta.dispatcher'
);

/** @namespace Pwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible)),
    updateMeta: (meta) => MetaDispatcher.then(() => dispatch(updateMeta(meta))),
});

/** @namespace Pwa/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    meta: {
        keywords: state.ConfigReducer.default_keywords,
        title: state.ConfigReducer.default_title,
        description: state.ConfigReducer.default_description,
    },
});

/** @namespace Pwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    static propTypes = {
        ...SourceHomePageContainer.propTypes,
        updateMeta: PropTypes.func.isRequired,
        meta: PropTypes.shape({
            keywords: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    };

    state = {
        loadRest: false,
    };

    updateHomepageMeta() {
        const { updateMeta, meta } = this.props;
        let cleanUrl = window.location.origin + window.location.pathname;
        cleanUrl = cleanUrl.replace(/\/$/, '');

        const newMeta = {
            ...meta,
            canonical_url: cleanUrl,
        };

        updateMeta({
            ...newMeta,
            status_code: 200,
        });
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateHomepageMeta();
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
        this.reloadTimer = autoReloadProducts();

        setTimeout(() => {
            this.setState({ loadRest: true });
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimer);
    }

    renderFirstBlock() {
        return (
                <CmsBlock
                  placeHolderSize={ { width: '100vw', height: '43vw' } }
                  identifier="slider_home"
                  blockType="SliderHome"
                />
        );
    }

    renderOtherBlocks() {
        return (
            <>
                    <IfStore storeCode="default">
                        <HomepageSlider flashSaleCode="carousel" />
                    </IfStore>
                    <IfStore storeCode="spain">
                        <HomepageSlider flashSaleCode="carousel-es" />
                    </IfStore>
                <IfStore storeCode="default">
                    { [1, 2, 3, 4, 5, 6, 7].map((num) => (
                            <CmsBlock
                              placeHolderSize={ { width: '100vw', height: '500px' } }
                              identifier={ `homepage_${num}` }
                            />
                    )) }
                    <div className="CmsBlock_fullWidth">
                                        <CmsBlock
                                          placeHolderSize={ {
                                              width: '100vw',
                                              height: '500px',
                                          } }
                                          identifier="homepage_cataloghi"
                                          fullWidth
                                        />
                    </div>
                </IfStore>
                <IfStore storeCode="spain">
                    { [1, 2, 3, 4, 5, 6].map((num) => (
                            <CmsBlock
                              placeHolderSize={ { width: '100vw', height: '500px' } }
                              identifier={ `homepage-es_${num}` }
                            />
                    )) }
                    <Swogo bundleId={ 2 } />
                    <div className="CmsBlock_fullWidth">
                                        <CmsBlock
                                          placeHolderSize={ {
                                              width: '100vw',
                                              height: '500px',
                                          } }
                                          identifier="homepage_cataloghi"
                                          fullWidth
                                        />
                    </div>
                </IfStore>
            </>
        );
    }

    render() {
        return (
            <>
                <ResetScroll />
                <div block="HomePage">
                    <div className="CmsPage-Wrapper">
                        <div className="CmsPage-Content">
                            { this.renderFirstBlock() }
                            { this.state.loadRest && this.renderOtherBlocks() }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
