import { PureComponent } from 'react';

import Link from 'Component/Link';

// import ProductSlider from 'Component/ProductSlider/ProductSlider.component';
//  import { SliderProductsType } from 'Type/SliderProductsType';
import './HomepageSlider.style';

/** @namespace Pwa/Component/HomepageSlider/HomepageSliderBlackFriday/Component */
export class HomepageSliderBlackFridayComponent extends PureComponent {
    renderTitle() {
        return (
            <div className="row">
                <div className="col-12 title">
                    <h5>{ __('BLACK WEEK: prodotti fino al 60% di SCONTO') }</h5>
                    <Link
                      className="Button"
                      block="ViewAll"
                      elem="CTA"
                        // mods={ mods }
                      to="/offerte/fine-serie.html"
                      id="home-sconto_iva-cta"
                    >
                        <span>
                            { __('SCOPRILI TUTTI') }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        return null;
    }
}

export default HomepageSliderBlackFridayComponent;
